@import url('https://fonts.googleapis.com/css2?family=Pangolin&display=swap');
html, body { font-family: 'Pangolin', cursive; }

html, body {
  margin: 0;
  overscroll-behavior: none;
  overflow: hidden;
  height: 100%;
  position: fixed;
  font-size: xx-large;
}

body {
  position: fixed;
}

img, span {
  user-select: none;
  pointer-events: none;
}
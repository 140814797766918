@import url(https://fonts.googleapis.com/css2?family=Pangolin&display=swap);
html, body { font-family: 'Pangolin', cursive; }

html, body {
  margin: 0;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  overflow: hidden;
  height: 100%;
  position: fixed;
  font-size: xx-large;
}

body {
  position: fixed;
}

img, span {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
